// Second 300x250 and 300x600
googletag.cmd.push(function() {
    var mappingSidebar = googletag.sizeMapping().
    addSize([0, 0], []).
    addSize([750, 600], [[300, 250], [300, 600]]).build();

    googletag.defineSlot('/62616433/main_sidebar/med_rec_homepage_only', [[300, 600], [300, 250]], 'div-gpt-ad-1571442641856-0').defineSizeMapping(mappingSidebar).addService(googletag.pubads());
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.enableServices();

});